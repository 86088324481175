import { type FunctionComponent, type ReactNode } from 'react'

/* utils */
import { classNames } from '../../utils/classNames'
import Tile from './Tile'

interface Props {
  icon: ReactNode
  value: number | string | ReactNode
  label: string
  className?: string
  active?: boolean
  children?: ReactNode
  onClick?: () => void
}

const Count: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { icon, value, label, active, children, className } = props

  return (
    <Tile className={classNames('flex flex-col items-center gap-1 px-4 py-6 md:px-8 mdlg:px-4', 'lg:px-8', className)} onClick={props.onClick} active={active}>
      <div className="flex w-full items-center">
        <div className="gap-1 px-2 lg:gap-2">
          <div className="font-headline text-xl font-bold tracking-wide text-th-text xs:text-2xl">{value}</div>
          <div className="text-sm font-bold text-th-text-secondary">{label}</div>
        </div>
        <div className="ml-auto h-full w-8 flex-none xl:w-10">{icon}</div>
      </div>
      {children}
    </Tile>
  )
}

export default Count
