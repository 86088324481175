import { type IBreadcrumbItem } from '@fluentui/react'
import { withAITracking } from '@microsoft/applicationinsights-react-js'

import { Fragment, type FunctionComponent, useEffect, useState } from 'react'

import {
  type Availability as AvailabilityType,
  type Price,
  type Product as ProductType,
  type Promotion as PromotionType,
  type RequiredProduct,
  type Sku as SkuType,
  type Subscription
} from '../../../types/microsoft365'

import axios, { type AxiosResponse } from 'axios'
import moment from 'moment'
import { Iconly } from 'react-iconly'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import SmoothScrollbar from 'smooth-scrollbar'

import { showAlert } from '../../../actions/alertsActions'
import { startLoading, stopLoading } from '../../../actions/loadingActions'
import Banner from '../../../components/Common/Banner'
import Breadcrumbs from '../../../components/Common/Breadcrumbs/Breadcrumbs'
import Button from '../../../components/Common/Button'
import Heading from '../../../components/Common/Heading'
import Input from '../../../components/Common/Input'
import Pagination from '../../../components/Common/Pagination'
import Section from '../../../components/Common/Section'
import Spacer from '../../../components/Common/Spacer'
import TenantDropdown from '../../../components/Dropdowns/TenantDropdown'
import { reactPlugin } from '../../../configs/appInsights'
import api from '../../../constants/api'
import Availability from './Availability/Availability'
import Product from './Product/Product'
import Promotion from './Promotion/Promotion'
import Sku from './Sku/Sku'

const Products: FunctionComponent = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const account = useSelector((state: RootStateOrAny) => state.account)
  const tenant = useSelector((state: RootStateOrAny) => state.tenant)

  /* subscription states */
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

  /* product states */
  const [filtered, setFiltered] = useState<ProductType[]>([])
  const [products, setProducts] = useState<ProductType[]>(new Array(12).fill(undefined))

  /* promotions states */
  const [promotions, setPromotions] = useState<PromotionType[]>([])
  const [availablePromotions, setAvailablePromotions] = useState<PromotionType[]>(new Array(12).fill(undefined))
  const [promotionsPage, setPromotionsPage] = useState<PromotionType[]>([])

  /* sku states */
  const [skus, setSkus] = useState<SkuType[]>([])
  const [availabilities, setAvailabilities] = useState<AvailabilityType[]>([])

  /* ui states */
  const [productsPage, setProductsPage] = useState<ProductType[]>([])
  const [skusPage, setSkusPage] = useState<SkuType[]>([])
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>([
    {
      text: 'All Products',
      key: 'All',
      onClick: () => {
        setBreadcrumbs([breadcrumbs[0]])
        navigate(`/products`)
      }
    }
  ])

  /* url params */
  const { product_id, sku_id } = useParams<{
    product_id: string
    sku_id: string
  }>()

  /* filter the products by search term */
  const filter = (searchTerm: string) => {
    setFiltered(
      products?.filter(
        (product: ProductType) =>
          product.Title.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 || product.Id.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
      )
    )
  }

  /* handle product click */
  const handleProductClick = (product: ProductType) => {
    navigate(`/products/${product.Id}`)
  }

  /* handle product promotions click */
  const handleProductPromotionsClick = (product: ProductType) => {
    navigate(`/products/${product.Id}/promotions`)
  }

  /* handle sku click */
  const handleSkuClick = (sku: SkuType) => {
    navigate(`/products/${sku.productId}/sku/${sku.id}`)
  }

  /* get the products skus */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (tenant && product_id && account.id) {
      dispatch(startLoading())

      api
        .get(`/ms-api/products/account/${account.id}/${product_id}`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          const product: ProductType = response.data

          /* update the breadcrumbs to include the product */
          if (!sku_id)
            setBreadcrumbs([
              breadcrumbs[0],
              {
                text: product.Title,
                key: product.Id
              }
            ])

          /* get the products skus */
          api
            .get(`/ms-api/products/account/${account.id}/${tenant.id}/${product_id}/skus`, { cancelToken: source.token })
            .then((response: AxiosResponse) => {
              const skus: SkuType[] = response.data.items
              setSkus(skus)
              if (!sku_id) dispatch(stopLoading())
            })
            .catch((error) => {
              if (!axios.isCancel(error)) {
                dispatch(
                  showAlert({
                    type: 'error',
                    message: `We are unable to retrieve the SKUs for product ${product_id}.`,
                    component: 'Products',
                    error
                  })
                )
                dispatch(stopLoading())
              }
            })
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: `We are unable to retrieve the SKUs for product ${product_id}.`,
                component: 'Products',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, product_id, sku_id, account.id])

  /* get the skus availabilities */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (tenant && product_id && sku_id && account.id) {
      dispatch(startLoading())

      api
        .get(`/ms-api/products/account/${account.id}/${tenant.id}/${product_id}/skus/${sku_id}`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          /* display the error message if there is one */
          if (response.data.code) {
            dispatch(
              showAlert({
                type: 'error',
                message: response.data.description,
                component: 'Products'
              })
            )
          }

          const availabilities: AvailabilityType[] = response.data.items

          /* get the skus prices and map them to the availabilties */
          api
            .get(`/ms-api/products/account/${account.id}/${product_id}/sku/${sku_id}/prices`, { cancelToken: source.token })
            .then((response: AxiosResponse) => {
              const prices = response.data as Price[]

              availabilities.forEach((availability) => {
                availability.terms.forEach((term) => {
                  const matchingPrices = prices.filter((price) => price.termDuration === term.duration && price.billingPlan.trim() === term.billingCycle)

                  if (!matchingPrices.length) return

                  const latestPrice = matchingPrices.sort((a, b) => moment(b.effectiveStartDate).diff(moment(a.effectiveStartDate)))[0]
                  term.price = latestPrice.erp
                })
              })

              setAvailabilities(availabilities)
              dispatch(stopLoading())
            })
            .catch((error) => {
              if (!axios.isCancel(error)) {
                dispatch(stopLoading())
                dispatch(
                  showAlert({
                    type: 'error',
                    message: `We are unable to retrieve the price information for SKU ${sku_id}.`,
                    component: 'Products',
                    error
                  })
                )
              }
            })

          /* update the breadcrumbs to include the sku and make the product clickable */
          setBreadcrumbs([
            breadcrumbs[0],
            {
              text: availabilities[0].product.title,
              key: availabilities[0].product.id,
              onClick: () => {
                /* we need to clear the product id and set it again to trigger the useEffect */
                navigate(`/products`)
                navigate(`/products/${product_id}`)
              }
            },
            {
              text: availabilities[0].skuId,
              key: availabilities[0].skuId
            }
          ])
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: `We are unable to retrieve the availabilities for SKU ${sku_id}.`,
                component: 'Products',
                error
              })
            )
            dispatch(stopLoading())
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, product_id, sku_id, account.id])

  /* get the customers subscriptions for checking prerequsites */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (tenant && account.id && tenant.id) {
      api
        .get(`/ms-api/subscriptions/account/${account.id}/${tenant.id}`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          setSubscriptions(response.data?.Items || [])
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: `We are unable to retrieve your subscriptions.`,
                component: 'Products',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, account.id])

  /* get the products for the customer */
  useEffect(() => {
    const source = axios.CancelToken.source()

    dispatch(stopLoading())
    setProducts([])
    setFiltered([])

    if (tenant && tenant.id && account.id) {
      api
        .get(`/ms-api/products/account/${account.id}/${tenant.id}/OnlineServices`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          setProducts(response.data)
          setFiltered(response.data)

          /* scroll back to the top of the page */
          const scrollbar = document.getElementsByClassName('scrollbar')[0] as HTMLElement
          if (scrollbar) SmoothScrollbar.get(scrollbar)?.setPosition(0, 0)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: error.response?.data,
                component: 'Products',
                error
              })
            )
          }
        })
    }

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, account.id])

  /* get a list of promotions */
  useEffect(() => {
    const source = axios.CancelToken.source()

    if (account.id)
      api
        .get(`/ms-api/products/account/${account.id}/promotions`, { cancelToken: source.token })
        .then((response: AxiosResponse) => {
          if (response.data) setPromotions(response.data.items)
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            dispatch(
              showAlert({
                type: 'error',
                message: 'We are unable to retrieve the product promotions.',
                component: 'Products',
                error
              })
            )
          }
        })

    return () => {
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.id])

  /* map promotions to their product */
  useEffect(() => {
    if (products?.length > 0 && promotions?.length > 0) {
      products.forEach((product: ProductType) => {
        promotions.forEach((promotion: PromotionType) => {
          promotion.requiredProducts.forEach((requiredProduct: RequiredProduct) => {
            if (requiredProduct.productId === product.Id) {
              product.Promotions = product.Promotions ? [promotion, ...product.Promotions] : [promotion]
            }
          })
        })
      })
    }
  }, [products, promotions])

  /* get the selected products promotions */
  useEffect(() => {
    if (location.pathname.endsWith('promotions') && products[0] !== undefined) {
      const availablePromotions = products.find((product: ProductType) => product.Id === product_id)?.Promotions
      if (availablePromotions) {
        setAvailablePromotions(availablePromotions)
      }
    }
  }, [location.pathname, products, product_id])

  /* availabilities component */
  const Availabilities: FunctionComponent<{
    availabilities: AvailabilityType[]
  }> = ({ availabilities }): JSX.Element => {
    return (
      <div className="flex flex-col gap-4">
        {availabilities?.map((availability: AvailabilityType) => <Availability availability={availability} tenant={tenant} key={availability.id} />)}
      </div>
    )
  }

  /* skus component */
  const Skus: FunctionComponent<{ skus: SkuType[] }> = ({ skus }): JSX.Element => {
    return (
      <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        {skus?.map((sku: SkuType) => <Sku sku={sku} subscriptions={subscriptions} onClick={() => handleSkuClick(sku)} key={sku.id} />)}
      </div>
    )
  }

  /* available promotions */
  const Promotions: FunctionComponent<{ promotions: PromotionType[] }> = ({ promotions }): JSX.Element => {
    /* populate the promotions array with undefined promotions for shimmer */
    if (promotions.length === 0) {
      promotions = new Array(12).fill(undefined) // replace with items per page?
    }

    return (
      <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {promotions?.map((promotion: PromotionType, index) => <Promotion promotion={promotion} key={index} />)}
      </div>
    )
  }

  /* products component */
  const Products: FunctionComponent<{ products: ProductType[] }> = ({ products }): JSX.Element => {
    /* populate the products array with undefined products for shimmer */
    if (products.length === 0) {
      products = new Array(12).fill(undefined) // replace with items per page?
    }

    return (
      <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {products.map((product: ProductType, index) => (
          <Product
            product={product}
            onClick={() => (product ? handleProductClick(product) : null)}
            onPromotionsClick={() => (product ? handleProductPromotionsClick(product) : null)}
            key={index}
          />
        ))}
      </div>
    )
  }

  return (
    <Fragment>
      <Section>
        <Banner>
          {/* breadcrumbs */}
          <Breadcrumbs items={breadcrumbs} />
          <Spacer />
          {/* tenant filter */}
          <TenantDropdown showAllTenants />
        </Banner>
      </Section>
      <Section>
        {sku_id ? (
          /* single sku view (availabilities) */
          <Fragment>
            <Heading text="Term Selection" />
            <Availabilities availabilities={availabilities} />
          </Fragment>
        ) : product_id ? (
          /* single product view (skus) or promotions */
          <Fragment>
            {location.pathname.endsWith('promotions') ? (
              /* promotions */
              availablePromotions ? (
                <Fragment>
                  <Heading text="Available Promotions">
                    <Button onClick={() => navigate(`/products/${product_id}`)}>View SKUs</Button>
                  </Heading>
                  <Promotions promotions={promotionsPage} />
                  {/* pagination element fixed to bottom of content component */}
                  <Pagination data={availablePromotions} onPageChange={(currentPage) => setPromotionsPage(currentPage)} />
                </Fragment>
              ) : (
                <Fragment>We couldn't find any promotions for this product.</Fragment>
              )
            ) : (
              /* skus */
              <Fragment>
                <Heading text="SKU Selection">
                  <Button onClick={() => navigate(`/products/${product_id}/promotions`)}>View Promotions</Button>
                </Heading>
                <Skus skus={skusPage} />
                {/* pagination element fixed to bottom of content component */}
                <Pagination data={skus} onPageChange={(currentPage) => setSkusPage(currentPage)} />
              </Fragment>
            )}
          </Fragment>
        ) : (
          /* product catalogue */
          <Fragment>
            <Heading text="Product Catalogue">
              <Spacer />
              {/* search */}
              {showSearch ? (
                <Fragment>
                  <Input type="text" name="search" placeholder="Search" autoFocus onChange={(e) => filter(e.target.value)} />
                  <IoCloseCircleOutline className="h-6 w-6 cursor-pointer" onClick={() => setShowSearch(false)} />
                </Fragment>
              ) : (
                <Iconly name="Search" set="light" className="cursor-pointer" onClick={() => setShowSearch(true)} />
              )}
            </Heading>
            {/* product catalogue */}
            <Products products={productsPage} />
            {/* pagination element fixed to bottom of content component */}
            <Pagination data={filtered} onPageChange={(currentPage) => setProductsPage(currentPage)} />
          </Fragment>
        )}
      </Section>
    </Fragment>
  )
}

const ProductsWithTracking = withAITracking(reactPlugin, Products, 'Products')
export default ProductsWithTracking
