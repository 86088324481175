import { type Price, type Subscription } from '../../../../types/microsoft365'

import api from 'src/constants/api'

import useQuery from 'src/hooks/utils/useQuery'

import moment from 'moment'

export const useSubscriptionPriceQuery = (accountId: string, subscription?: Subscription | null) => {
  const effectiveStartDate = moment(subscription?.EffectiveStartDate).format('YYYY-MM-DD')
  const productId = subscription?.OfferId.split(':')[0]
  const skuId = subscription?.OfferId.split(':')[1]
  const queryKey = ['subscriptionPrice', accountId, productId, skuId, effectiveStartDate]

  const queryResult = useQuery(
    queryKey,
    async ({ signal }) => {
      const { data } = await api.get(`/ms-api/products/account/${accountId}/${productId}/sku/${skuId}/prices/${effectiveStartDate}`, { signal })
      return data as Price[]
    },
    {
      enabled: !!accountId && !!subscription && !!subscription?.OfferId && subscription.ProductType?.Id === 'OnlineServicesNCE',
      refetchOnWindowFocus: false
    }
  )

  if (!Array.isArray(queryResult.data)) {
    return { ...queryResult, data: null }
  }

  const filteredData = queryResult.data?.filter(
    (p) =>
      p.billingPlan.trim().toLowerCase() === subscription?.BillingCycle.toLowerCase() &&
      p.termDuration === subscription?.TermDuration &&
      p.market === 'GB' &&
      p.termDuration.toLowerCase() === subscription?.TermDuration.toLowerCase()
  )

  const sortedData = filteredData?.sort((a, b) => moment(b.effectiveStartDate).diff(moment(a.effectiveStartDate)))
  const price = sortedData?.[0]

  return { ...queryResult, data: price }
}
