import { type UseQueryOptions, useQueries } from '@tanstack/react-query'

import { type Subscription } from 'src/types/microsoft365'

import api from 'src/constants/api'

import useAccount from 'src/hooks/utils/useAccount'
import useQuery from 'src/hooks/utils/useQuery'

// Keys for react-query
const subscriptionKeys = {
  all: ['subscriptions'] as const,
  byTenant: (accountId: string, tenantId: string) => [...subscriptionKeys.all, accountId, tenantId] as const
}

// Fetch function
const fetchSubscriptions = async (accountId: string, tenantId: string, signal?: AbortSignal): Promise<Subscription[]> => {
  if (!accountId || !tenantId) throw new Error('Account ID or Tenant ID is missing')

  const response = await api.get(`/ms-api/subscriptions/account/${accountId}/${tenantId}`, { signal })

  if (!response.data?.Items) throw new Error('No subscriptions found')

  return response.data?.Items as Subscription[]
}

// Single tenant query hook
const useSubscriptionsQuery = (tenantId: string | undefined, options?: UseQueryOptions<Subscription[]>) => {
  const account = useAccount()

  return useQuery(subscriptionKeys.byTenant(account.id, tenantId!), ({ signal }) => fetchSubscriptions(account.id, tenantId!, signal), {
    enabled: !!account.id && !!tenantId,
    ...options
  })
}

// Multiple tenant query hook
const useSubscriptionsQueries = (tenantIds: string[]) => {
  const account = useAccount()

  return useQueries({
    queries: tenantIds.map((tenantId) => ({
      queryKey: subscriptionKeys.byTenant(account.id, tenantId),
      queryFn: () => fetchSubscriptions(account.id, tenantId),
      enabled: !!account.id && !!tenantId,
      retry: false,
      retryOnMount: false,
      refetchOnWindowFocus: false
    }))
  })
}

export { useSubscriptionsQueries }
export default useSubscriptionsQuery
