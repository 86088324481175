import { useTenantsQuery } from '~/hooks/services/servicenow/queries/useTenantsQuery'

import React from 'react'

import { useSubscriptionsQueries } from 'src/hooks/services/microsoft/queries/useSubscriptionsQuery'

import { isExpiringSoon } from 'src/utils/subscriptions'

import moment from 'moment'
import { IoWarningOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import Count from '../Common/Count'
import Spinner from '../Common/Spinner'

const ExpiringLicensesTile: React.FC = () => {
  const navigate = useNavigate()
  const { data: tenants = [] } = useTenantsQuery()
  const queries = useSubscriptionsQueries(tenants.map((tenant) => tenant.id))

  const isLoading = queries.some((query) => query.isLoading)

  const subscriptionsWithTenants = queries
    .map((query, index) => {
      const tenant = tenants[index]
      return (query.data ?? []).map((subscription) => ({
        ...subscription,
        tenant
      }))
    })
    .flat()
    .filter((sub) => isExpiringSoon(sub) && !sub.AutoRenewEnabled)

  const totalCount = subscriptionsWithTenants.length

  return (
    <Count
      icon={<IoWarningOutline className="h-full w-full text-th-warning" />}
      value={
        <div className="flex items-center gap-2">
          <div className="text-2xl font-bold">{totalCount}</div>
          {isLoading && <Spinner className="h-4 w-4" />}
        </div>
      }
      label="Microsoft 365 Licenses Expiring Soon"
      onClick={() => navigate('/subscriptions?filter=expiring')}
      className="relative cursor-pointer"
    >
      <div className="flex w-full flex-col gap-3 px-2">
        {subscriptionsWithTenants.slice(0, 3).map((sub) => (
          <div key={sub.Id} className="mt-2 border-t border-th-border pt-2 text-sm">
            <div className="font-medium">{sub.FriendlyName}</div>
            <div className="flex justify-between text-th-text-secondary">
              <span>{sub.tenant.name}</span>
              <span>Expires {moment(sub.CommitmentEndDate).format('DD/MM/YYYY')}</span>
            </div>
          </div>
        ))}

        {totalCount > 3 && (
          <div className="border-t border-th-border pt-2 text-center text-sm text-th-text-secondary">View {totalCount - 3} more expiring licenses...</div>
        )}
      </div>
    </Count>
  )
}

export default ExpiringLicensesTile
