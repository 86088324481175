import { withAITracking } from '@microsoft/applicationinsights-react-js'
import AutoRenewingLicensesTile from '~/components/Dashboard/AutoRenewingLicensesTile'

import { Fragment, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import { stopLoading } from '../../actions/loadingActions'
import ArticleLinkList from '../../components/Common/ArticleLinkList'
import Heading from '../../components/Common/Heading'
import Section from '../../components/Common/Section'
import Spacer from '../../components/Common/Spacer'
import ExpiringLicensesTile from '../../components/Dashboard/ExpiringLicensesTile'
import { reactPlugin } from '../../configs/appInsights'
import Azure from './Azure/Azure'
import KeyContacts from './KeyContacts/KeyContacts'
import SecureScores from './SecureScores/SecureScores'
import Tickets from './Tickets/Tickets'

const Dashboard = (): JSX.Element => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(stopLoading())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Fragment>
      <Section>
        <Tickets />
        <Spacer />
        <div className="grid grid-cols-1 gap-8 xl:grid-cols-2 2xl:grid-cols-3">
          <SecureScores />
          <div className="xl:col-span-1 2xl:col-span-2">
            <Azure />
          </div>
        </div>
        <Spacer />
        <div className="grid grid-cols-1 gap-8 xl:grid-cols-3">
          <KeyContacts title="My Account" />
          <ExpiringLicensesTile />
          <AutoRenewingLicensesTile />
        </div>
        <Spacer />
        <Heading text="External resources" />
        <ArticleLinkList />
      </Section>
    </Fragment>
  )
}

const DashboardWithTracking = withAITracking(reactPlugin, Dashboard, 'Dashboard')
export default DashboardWithTracking
