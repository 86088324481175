import { useQuery } from '@tanstack/react-query'

import { Fragment, type FunctionComponent, useState } from 'react'

import moment, { type Moment } from 'moment'
import { Iconly } from 'react-iconly'
import { FcAlarmClock, FcDocument, FcOk } from 'react-icons/fc'
import { IoTicket } from 'react-icons/io5'
import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { closeDrawers, openNewTicketDrawer } from '../../../actions/drawerActions'
import Button from '../../../components/Common/Button'
import Count from '../../../components/Common/Count'
import Heading from '../../../components/Common/Heading'
import TicketTable from '../../../components/Tables/TicketTable'
import api from '../../../constants/api'

const lastDayOfThisMonth = moment().endOf('month')
const startOfAllTime = moment('1970-01-01').startOf('day')

type TicketFilterOptions = {
  startDate: Moment
  endDate: Moment
  keyword: string | null
  priority: string[] | null
  category: string[] | null
  state: string | null
  orderBy: string | null
  orderByDesc: boolean
  take: number
  skip: number
}

type TicketStats = {
  open: number
  awaiting_response: number
  awaiting: number
  closed: number
}

const DEFAULT_FILTER_OPTIONS: TicketFilterOptions = {
  startDate: startOfAllTime,
  endDate: lastDayOfThisMonth,
  keyword: null,
  priority: ['Critical', 'High', 'Medium', 'Low'],
  category: ['Request', 'Complaint', 'Incident', 'Event', 'Proactive'],
  state: 'Awaiting Response',
  orderBy: null,
  orderByDesc: false,
  take: 10,
  skip: 0
}

const Tickets: FunctionComponent = (): JSX.Element => {
  const account = useSelector((state: RootStateOrAny) => state.account)

  const [filterOptions, setFilterOptions] = useState<TicketFilterOptions>(DEFAULT_FILTER_OPTIONS)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data: ticketStats } = useQuery({
    queryKey: ['ticketStats', account.id],
    queryFn: async ({ signal }) => {
      try {
        const response = await api.post(
          `/servicenow/account/${account.id}/tickets/stats`,
          {
            start_date: startOfAllTime.format('YYYY-MM-DD'),
            end_date: lastDayOfThisMonth.format('YYYY-MM-DD')
          },
          {
            signal
          }
        )
        return response.data as TicketStats
      } catch (error) {
        console.error(error)
      }

      return null
    },
    enabled: !!account.id
  })

  return (
    <Fragment>
      <Heading text={'Tickets'}>
        <Button
          variant="primary"
          onClick={() => {
            dispatch(closeDrawers())
            dispatch(openNewTicketDrawer())
          }}
          className="flex gap-2"
        >
          <span className="mr-1.5 flex h-5 w-5 items-center">
            <Iconly name="Bookmark" set="light" />
          </span>
          New Ticket
        </Button>
      </Heading>
      {/* ticket tiles */}
      <div className="my-5 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 mdlg:grid-cols-2 xl:grid-cols-4">
        <Count
          icon={<IoTicket className="h-full w-full text-th-warning" />}
          value={ticketStats?.open || 'Loading...'}
          label="Open"
          onClick={() => navigate('/tickets')}
          className="relative cursor-pointer"
        ></Count>
        <Count
          icon={<FcDocument className="h-full w-full" />}
          value={ticketStats?.awaiting_response || 'Loading...'}
          label="Awaiting Response"
          onClick={() => navigate('/tickets')}
          className="relative cursor-pointer"
        ></Count>
        <Count
          icon={<FcAlarmClock className="h-full w-full" />}
          value={ticketStats?.awaiting || 'Loading...'}
          label="Awaiting Action"
          onClick={() => navigate('/tickets')}
          className="relative cursor-pointer"
        ></Count>
        <Count
          icon={<FcOk className="h-full w-full" />}
          value={ticketStats?.closed || 'Loading...'}
          label="Closed"
          onClick={() => navigate('/tickets')}
          className="relative cursor-pointer"
        ></Count>
      </div>

      {/* tickets with unseen updates */}
      <div className="my-4 font-bold sm:text-lg">Awaiting Response</div>
      {/* loading spinner */}
      <div className="relative z-0 overflow-hidden rounded-2xl border border-th-border p-1">
        <TicketTable filterOptions={filterOptions} onFilterChange={setFilterOptions} />
      </div>
    </Fragment>
  )
}

export default Tickets
