import { classNames } from '~/utils/classNames'

import { type FunctionComponent, type ReactNode } from 'react'

import Popover from './Popover'

interface Props {
  icon: ReactNode
  popoverText: string
  className?: string
}

const Pin: FunctionComponent<Props> = (props: Props): JSX.Element => {
  const { icon, popoverText, className } = props

  return (
    <Popover text={popoverText}>
      <div className={classNames('flex h-8 w-8 items-center justify-center rounded-full bg-th-content-secondary', className)}>{icon}</div>
    </Popover>
  )
}

export default Pin
