import { type Price, type Subscription } from 'src/types/microsoft365'

import moment from 'moment'

export const calculateAnnualizedPrice = (price: Price, subscription: Subscription): number => {
  if (subscription.BillingCycle === 'monthly' && subscription.TermDuration === 'P1Y') {
    return price.erp / 12
  } else if (subscription.BillingCycle === 'monthly' && subscription.TermDuration === 'P3Y') {
    return price.erp / 36
  } else if (subscription.BillingCycle === 'annual' && subscription.TermDuration === 'P3Y') {
    return price.erp / 3
  } else {
    return price.erp
  }
}

export const formatPrice = (price: number, subscription: Subscription): string => {
  const formattedPrice = price.toFixed(2)
  if (subscription.BillingCycle === 'monthly') {
    return `£${formattedPrice} / Month`
  } else {
    return `£${formattedPrice} / Year`
  }
}

export const calculatePriceChange = (price: Price, subscription: Subscription, newQuantity: number, oldQuantity: number): number => {
  const annualizedPrice = calculateAnnualizedPrice(price, subscription)
  return annualizedPrice * (newQuantity - oldQuantity)
}

export const isExpiringSoon = (subscription: Subscription): boolean => {
  const commitmentEndDate = moment(subscription.CommitmentEndDate)
  const daysUntilCommitmentEnd = commitmentEndDate.diff(moment(), 'days')
  return daysUntilCommitmentEnd <= 30 && daysUntilCommitmentEnd > 0
}

export const getDaysUntilCommitmentEnd = (subscription: Subscription): number => {
  return moment(subscription.CommitmentEndDate).diff(moment(), 'days')
}
