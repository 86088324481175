import { type FormEvent, Fragment, type FunctionComponent, useState } from 'react'

import { type RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { showAlert } from '../../actions/alertsActions'
import { closeDrawers } from '../../actions/drawerActions'
import api from '../../constants/api'
import Button from '../Common/Button'
import Callout from '../Common/Callout'
import Drawer from '../Common/Drawer'
import Input from '../Common/Input'
import Select from '../Common/Select'
import Submit from '../Common/Submit'
import TextArea from '../Common/TextArea'

/* ticket categories */
const categoryOptions = [
  {
    label: 'Incident',
    value: 'Incident'
  },
  {
    label: 'Request',
    value: 'Request'
  }
]

/* users affected */
const usersAffectedOptions = [
  {
    label: 'Single user',
    value: 'Single'
  },
  {
    label: 'Multiple users',
    value: 'Multiple'
  },
  {
    label: 'All users',
    value: 'All'
  }
]

/* business impact */
const businessImpactOptions = [
  {
    label: 'No Immediate Impact',
    value: 'Not Immediate'
  },
  {
    label: 'Some Impact',
    value: 'Some'
  },
  {
    label: 'Significant Impact',
    value: 'Significant'
  }
]

/* form names */
interface Forms extends HTMLCollectionOf<HTMLFormElement> {
  newTicketForm: HTMLFormElement
}

const NewTicket: FunctionComponent = (): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const account = useSelector((state: RootStateOrAny) => state.account)

  /* drawer actions */
  const Actions: FunctionComponent = (): JSX.Element => {
    return (
      <Fragment>
        <Button bordered onClick={() => dispatch(closeDrawers())}>
          Cancel
        </Button>
        <Submit variant="primary" form="newTicketForm" />
      </Fragment>
    )
  }

  const newTicket = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const forms = document.forms as Forms
    const form = forms.newTicketForm
    const data = new FormData(form)

    const title = data.get('title')
    const customerRef = data.get('customer_ticket_reference')

    const ticket = {
      account: account.id,
      contact_type: 'Provide',
      category: data.get('category'),
      title: customerRef ? `[${customerRef}] ${title}` : title,
      users_affected: data.get('users_affected'),
      business_impact: data.get('business_impact'),
      description: data.get('description'),
      customer_ticket_reference: customerRef || ''
    }

    api
      .post(`/servicenow/account/${account.id}/ticket`, ticket)
      .then((response) => {
        setLoading(false)
        dispatch(closeDrawers())

        /* trigger ticket table re-render to display the new ticket */
        navigate('/tickets', { state: ticket })

        dispatch(
          showAlert({
            type: 'success',
            message: response.data.number + ' created.',
            component: 'NewTicket'
          })
        )
      })
      .catch((error) => {
        setLoading(false)
        dispatch(
          showAlert({
            type: 'error',
            message: 'We were unable to create your ticket. Please try again.',
            component: 'NewTicket',
            error
          })
        )
      })
  }

  return (
    <Drawer title={'New Ticket'} loading={loading} actions={<Actions />}>
      <form onSubmit={(e) => newTicket(e)} id="newTicketForm" className="flex flex-col gap-4">
        <Callout type="warning" text="Changes will not be saved if you close this drawer." />
        {/* category */}
        <Select name="category" label="Category" options={categoryOptions} required />
        {/* title */}
        <Input type="text" name="title" label="Title" required />
        {/* customer ticket reference */}
        <Input type="text" name="customer_ticket_reference" label="Customer Ticket Reference" />
        {/* users affected */}
        <Select name="users_affected" label="Users Affected" options={usersAffectedOptions} required />
        {/* business impact */}
        <Select name="business_impact" label="Business Impact" options={businessImpactOptions} required />
        {/* description */}
        <TextArea className="h-32" name="description" label="Description" required />
      </form>
    </Drawer>
  )
}

export default NewTicket
