import { withAITracking } from '@microsoft/applicationinsights-react-js'
import Banner from '~/components/Common/Banner'
import Button from '~/components/Common/Button'
import Section from '~/components/Common/Section'
import Spacer from '~/components/Common/Spacer'
import Spinner from '~/components/Common/Spinner'
import TenantsDropdown from '~/components/Dropdowns/TenantsDropdown'
import { reactPlugin } from '~/configs/appInsights'

import React, { useMemo, useState } from 'react'

import { type OptionTypeBase } from 'react-select/src/types'
import type { Tenant } from '~/types/azure'

import { useSubscriptionsQueries } from 'src/hooks/services/microsoft/queries/useSubscriptionsQuery'

import Dropdown from 'src/components/Common/Dropdown'

import { isExpiringSoon } from 'src/utils/subscriptions'

import { FcCancel, FcCheckmark, FcClock, FcMinus } from 'react-icons/fc'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Subscription from './Subscription/Subscription'

const stateOptions = [
  {
    label: (
      <span className="flex items-center gap-2">
        <FcCheckmark /> Active
      </span>
    ),
    value: 'active'
  },
  {
    label: (
      <span className="flex items-center gap-2">
        <FcMinus /> Pending
      </span>
    ),
    value: 'pending'
  },
  {
    label: (
      <span className="flex items-center gap-2">
        <FcCancel /> Deleted
      </span>
    ),
    value: 'deleted'
  },
  {
    label: (
      <span className="flex items-center gap-2">
        <FcClock /> Expired
      </span>
    ),
    value: 'expired'
  }
]

const filterOptions = [
  {
    label: 'Show All',
    value: 'all'
  },
  {
    label: 'Expiring Soon',
    value: 'expiring'
  },
  {
    label: 'Auto-Renewing Soon',
    value: 'renewal-enabled'
  }
]

const Subscriptions: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [states, setStates] = useState<string[]>([stateOptions[0].value])
  const [filter, setFilter] = useState<string>(searchParams.get('filter') ?? 'all')
  const navigate = useNavigate()

  const [tenants, setTenants] = useState<Tenant[]>([])
  const queries = useSubscriptionsQueries(tenants.map((tenant) => tenant.id))
  const subscriptionsWithTenants = queries
    .map((query, index) => {
      const tenant = tenants[index]
      return (query.data ?? []).map((subscription) => ({
        ...subscription,
        tenant
      }))
    })
    .flat()
    .filter((subscription) => subscription !== undefined)

  const isLoading = queries.some((query) => query.isLoading)

  const filteredSubscriptions = useMemo(() => {
    if (!subscriptionsWithTenants) return []

    let filtered = subscriptionsWithTenants.filter((subscription) => states.includes(subscription.Status))

    switch (filter) {
      case 'expiring':
        filtered = filtered.filter((subscription) => isExpiringSoon(subscription) && !subscription.AutoRenewEnabled)
        break
      case 'renewal-enabled':
        filtered = filtered.filter((sub) => sub.AutoRenewEnabled && isExpiringSoon(sub))
        break
    }

    return filtered
  }, [subscriptionsWithTenants, states, filter])

  return (
    <>
      <Section>
        <Banner>
          <div className="flex items-center gap-4">
            <div className="flex gap-4">
              <Dropdown
                options={filterOptions}
                value={filterOptions.find((opt) => opt.value === filter)}
                label="Filter"
                onChange={(selected: OptionTypeBase) => setFilter(selected.value)}
                bordered
                multi={false}
              />
              <Dropdown
                options={stateOptions}
                defaultValue={[stateOptions[0]]}
                label="State"
                onChange={(selected: OptionTypeBase) => setStates(selected.map((option: any) => option.value))}
                multi
                bordered
              />
            </div>
          </div>
          <Spacer />
          {isLoading && (
            <div className="flex items-center justify-center gap-4">
              <Spinner className="h-4 w-4" />
              <div>Loading your subscriptions...</div>
            </div>
          )}
          <TenantsDropdown onChange={setTenants} />
        </Banner>
      </Section>
      <Section>
        {filteredSubscriptions.length === 0 && !isLoading ? (
          <div className="-mt-48 flex h-screen flex-col items-center justify-center gap-8">
            <div>We couldn't find any subscriptions matching your filters.</div>
            <div>You can purchase new subscriptions from our product catalogue!</div>
            <Button variant="primary" onClick={() => navigate('/products')}>
              View Products
            </Button>
          </div>
        ) : (
          <div className="grid gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {filteredSubscriptions.map((subscription) => (
              <Subscription subscription={subscription} key={subscription.Id} />
            ))}
          </div>
        )}
      </Section>
    </>
  )
}

const SubscriptionsWithTracking = withAITracking(reactPlugin, Subscriptions, 'Subscriptions')
export default SubscriptionsWithTracking
