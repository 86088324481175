import { useMemo } from 'react'

import { type Tenant } from 'src/types/azure'

import { useTenantsQuery } from 'src/hooks/services/servicenow/queries/useTenantsQuery'

import { type TreeNode } from 'react-dropdown-tree-select'

import NestedDropdown from '../Common/RefactoredDropdown'

type TenantsDropdownProps = {
  onChange?: (selectedTenants: Tenant[]) => void
  className?: string
}

const TenantsDropdown: React.FC<TenantsDropdownProps> = ({ onChange, className }) => {
  const { data: tenants, isLoading } = useTenantsQuery()

  const data = useMemo(() => {
    const mappedData = tenants.map((tenant) => ({
      label: tenant.name,
      value: tenant.id,
      expanded: true,
      checked: true
    }))

    onChange?.(tenants)
    return mappedData
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants])

  const handleDropdownChange = (_curr: TreeNode, _data: TreeNode[]) => {
    const topLevel = _data.filter((d) => d._depth === 0)

    const selectedTenants = tenants.filter((tenant) => topLevel.some((t) => tenant.id === t.value))

    onChange?.(selectedTenants)
  }

  return <NestedDropdown data={data} onChange={handleDropdownChange} label="Tenants" loading={isLoading} className={className} />
}

export default TenantsDropdown
